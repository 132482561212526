import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home'),
    },
    {
        path: '/card/:id?',
        name: 'card',
        components: {
            default: () => import('@/views/Card'),
            list: () => import('@/views/Cards'),
        },
    },
    {
        path: '/music/:id?',
        name: 'music',
        components: {
            default: () => import('@/views/Music'),
            list: () => import('@/views/Musics'),
        },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/About'),
    },
    {
        path: '/:k/:id',
        name: 'default',
        component: () => import('@/views/Default'),
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
