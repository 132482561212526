Array.prototype.mapBy = function(k, v) {
    const result = {};
    if (!v) {
        v = x => x;
    }

    for (let item of this) {
        result[k(item)] = v(item);
    }

    return result;
};

Array.prototype.groupBy = function(k, v) {
    const result = {};
    if (!v) {
        v = x => x;
    }

    for (let item of this) {
        let key = k(item);
        result[key] = result[key] ?? [];
        result[key].push(item);
    }

    for (let key in result) {
        result[key] = v(result[key]);
    }

    return result;
};
