import './util';

// vue
import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);

// vue-router
import router from '@/plugins/router';
app.use(router);

// vuetify
import vuetify from '@/plugins/vuetify';
import { loadFonts } from '@/plugins/webfontloader';
app.use(vuetify);
loadFonts();

// vue-i18n
import i18n from '@/plugins/i18n';
app.use(i18n);

// sekai
import sekai from './sekai';
app.config.globalProperties.$sekai = sekai;
app.config.globalProperties.$db = sekai.database;

import settings from './settings';
app.config.globalProperties.$settings = settings;

// components
import DataTable from '@/components/DataTable';
app.component('DataTable', DataTable);

// mount
app.mount('#app');

