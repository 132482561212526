export default {
  "app": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ぷろせかもえ！"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非公式プロセカデータ分析サイト"])},
    "fontFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"Avenir\", \"Verdana\", \"Helvetica\", \"ヒラギノ角ゴシック\", \"Hiragino Sans\", \"ヒラギノ角ゴ ProN W3\", \"Hiragino Kaku Gothic ProN\", \"メイリオ\", \"Meiryo\", \"ＭＳ Ｐゴシック\", \"MS PGothic\", \"sans-serif\""])}
  },
  "card": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンバー"])}
  },
  "cardRarities": {
    "rarity_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["★1"])},
    "rarity_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["★2"])},
    "rarity_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["★3"])},
    "rarity_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["★4"])},
    "rarity_birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "dataTable": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データ表"])},
    "key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キー"])},
    "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["値"])}
  },
  "loading": {
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データベース読み込み中"])}
  },
  "navigation": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム"])},
    "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キャラクター"])},
    "musics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楽曲"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])}
  },
  "music": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楽曲"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["譜面"])},
    "scoreOpenInNew": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("difficulty")), " 譜面を新しいタブで開く"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("app.title", undefined, _type), " について"])}
  }
}