import * as idb from 'idb-keyval';
import axios from 'axios';
import JSONbig from 'json-bigint';

import { reactive } from 'vue';

function Game(object) {
    for (let key in object) {
        this[key] = object[key];
    }

    this.database = {};
    this.databaseStatus = reactive({
        status: '',
        loaded: 0,
        promise: null,
    });

    this.loadDatabase = function () {
        this.databaseStatus.promise = async function () {
            if (this.databaseStatus.status) {
                return;
            }

            let localVersion = await this.loadLocalDatabase();
            let version = await axios.get(`${this.databaseHost}/version.json?t=${new Date().getTime()}`).then(response => response.data.dataVersion);

            if (!localVersion) {
                this.databaseStatus.status = 'loading';
                await this.fetchDatabase(version);
                this.databaseStatus.status = 'ok';
            } else if (localVersion != version) {
                this.databaseStatus.status = 'updating';
                await this.fetchDatabase(version);
                this.databaseStatus.status = 'ok';
            }
        }.bind(this)();
    };

    this.loadLocalDatabase = async function () {
        try {
            let localDatabase = await idb.get(this.id);
            let localVersion = localDatabase?.version;
            if (!localVersion) {
                return localVersion;
            }
            if (localDatabase) {
                Object.keys(localDatabase).forEach((name) => {
                    this.database[name] = localDatabase[name];
                    this.requiredDatabases.forEach(requiredDatabase => {
                        if (requiredDatabase.name == name) {
                            requiredDatabase.ok = true;
                        }
                    });
                });
                this.database.version = localVersion;
                this.databaseStatus.status = 'ok';
            }
            if (!this.requiredDatabases.map((requiredDatabase) => requiredDatabase.ok || false).reduce((x, y) => x && y)) {
                return true;
            }
            return localVersion;
        } catch (e) {
            // eslint-disable-next-line
            console.log('Error occurs when accessing indexedDB: ' + e);
        }
    };

    this.fetchDatabase = async function (version) {
        const self = this;
        for (let key in this.database) {
            delete this.database[key];
        }

        this.databaseStatus.loaded = 0;
        await axios.all(this.requiredDatabases.map((requiredDatabase) =>
            axios.get(`${requiredDatabase.host || this.databaseHost}/${requiredDatabase.uri || requiredDatabase.name}.json?t=${new Date().getTime()}`).then((data) => {
                this.databaseStatus.loaded += 1;
                // window.vue.$emit('databaseLoading', {
                //   gameID: this.id,
                //   loaded: loaded,
                //   total: this.requiredDatabases.length,
                // });
                return data;
            }),
        )).then(axios.spread(function () {
            self.requiredDatabases.forEach((requiredDatabase, i) => {
                let data = arguments[i].data;
                let database = {};
                if (requiredDatabase.function) {
                    database = requiredDatabase.function(data);
                } else if (requiredDatabase.key) {
                    // eslint-disable-next-line
                    for (let item of data) {
                        database[item[requiredDatabase.key]] = item;
                    }
                    // database[`${requiredDatabase.name}Array`] = data;
                } else {
                    // database[requiredDatabase.name] = data;
                }
                if (!self.database[requiredDatabase.name]) {
                    self.database[requiredDatabase.name] = {};
                }
                for (let key in database) {
                    if (Array.isArray(database[key])) {
                        self.database[requiredDatabase.name][key] = database[key];
                    } else {
                        self.database[requiredDatabase.name][key] = Object.assign({}, self.database[requiredDatabase.name][key] || {}, database[key]);
                    }
                }
            });
        }));
        try {
            // let date = Vue.prototype.$time.toJSON();
            this.database.version = version;
            // database.date = date;
            await idb.set(this.id, this.database);
            // await set('databaseDate', date);
        } catch (e) {
            // eslint-disable-next-line
        }
    };
}


const sekai = new Game({
    id: 'pjsekai',
    databaseHost: 'https://database.pjsekai.moe',
    assetHost: 'https://asset3.pjsekai.moe',
    api: function (url) {
        return axios.get('https://api.pjsekai.moe' + url, {
            transformResponse: data => JSONbig({ storeAsString: true }).parse(data),
        }).then(response => response.data);
    },
    requiredDatabases: [
        { name: 'outsideCharacters', key: 'id' },
        { name: 'gameCharacters', key: 'id' },
        { name: 'characterProfiles', key: 'characterId' },
        { name: 'gameCharacterUnits', key: 'id' },
        { name: 'cards', key: 'id' },
        { name: 'events', key: 'id' },
        { name: 'eventCards', key: 'id' },
        { name: 'eventMusics', key: 'eventId' },
        { name: 'honors', key: 'id' },
        { name: 'honorGroups', key: 'id' },
        { name: 'bonds', key: 'id' },
        { name: 'bondsHonors', key: 'id' },
        { name: 'bondsHonorWords', key: 'id' },
        { name: 'musics', key: 'id' },
        { name: 'musics', key: 'id', host: 'https://musics.pjsekai.moe' },
        {
            name: 'musicDifficulties', function: function (data) {
                let musicDifficulties = {};
                for (let musicDifficulty of data) {
                    if (!musicDifficulties[musicDifficulty.musicId]) {
                        musicDifficulties[musicDifficulty.musicId] = {};
                    }
                    musicDifficulties[musicDifficulty.musicId][musicDifficulty.musicDifficulty] = musicDifficulty;
                }
                return musicDifficulties;
            }
        },
        {
            name: 'musicDifficulties', host: 'https://musics.pjsekai.moe', function: function (data) {
                let musicDifficulties = {};
                for (let musicDifficulty of data) {
                    if (!musicDifficulties[musicDifficulty.musicId]) {
                        musicDifficulties[musicDifficulty.musicId] = {};
                    }
                    musicDifficulties[musicDifficulty.musicId][musicDifficulty.musicDifficulty] = musicDifficulty;
                }
                return musicDifficulties;
            }
        },
        {
            name: 'musicVocals', function: function (data) {
                let musicVocals = {};
                for (let musicVocal of data) {
                    if (!musicVocals[musicVocal.musicId]) {
                        musicVocals[musicVocal.musicId] = [];
                    }
                    musicVocals[musicVocal.musicId].push(musicVocal);
                }
                return musicVocals;
            }
        },
        { name: 'rankMatchSeasons', key: 'id' },
        { name: 'rankMatchClasses', key: 'id' },
        { name: 'rankMatchGrades', key: 'id' },
        { name: 'rankMatchTiers', key: 'id' },

        { name: 'unitProfiles', key: 'unit' },
        { name: 'gachas', key: 'id' },
        // { name: 'materials', key: 'id' },
        // { name: 'gachaTickets', key: 'id' },

        { name: 'cardRarities', key: 'cardRarityType' },
    ],
    eventStartID: 31,
});

export default sekai;
