// Styles
import '@mdi/font/css/materialdesignicons.css';
// import 'vuetify/styles';
import '@/scss/main.scss';
// import 'vuetify';

// Vuetify
import { createVuetify } from 'vuetify';
import { VDataTable } from 'vuetify/labs/VDataTable';
import { VInfiniteScroll } from 'vuetify/labs/VInfiniteScroll';

import colors from 'vuetify/lib/util/colors';

export default createVuetify({
    // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    components: {
        VDataTable,
        VInfiniteScroll,
    },
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: colors.purple.lighten2,
                    secondary: '#999999',
                    background: '#EFEFEF',
                    surface: '#F7F7F7',
                },
            },
            dark: {
                dark: true,
                colors: {
                    primary: '#666666',
                    secondary: '#999999',
                    background: '#111111',
                },
            },
        },
    }
});
